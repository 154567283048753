import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Group, Segment, useManager } from 'entities/sketch/Manager';
import { ReactComponent as CreateEditGroupOfSimilarSVG } from './icons/create_edit_group_of_similar.svg';


export function EditGroupButton() {
    const manager = useManager('self');
    const selectedElements = useManager('selectedElements');
    const inpainting = useManager('inpainting');
    const inpaintingQueue = useManager('inpaintingQueue');

    const disabled = useMemo(() => {
        if (selectedElements.length === 0) return false;
        if (selectedElements.some(element => element instanceof Group) && selectedElements.length !== 1) return true;

        if (inpainting && selectedElements.some(element => inpainting.element === element)) return true;
        if (selectedElements.some(element => inpaintingQueue.includes(element))) return true;
    }, [selectedElements, inpainting, inpaintingQueue]);

    const handleCreateGroup = useCallback(async () => {
        if (selectedElements.some(element => element instanceof Group)) throw new Error('Selected elements have Group.');
        const group = manager.createGroup(selectedElements as Array<Segment>);
        manager.editingGroup = group;
    }, [selectedElements, manager]);

    const handleEdit = useCallback(() => {
        const group = selectedElements[0];
        if (!(group instanceof Group)) throw new Error('Element is not group.');
        manager.editingGroup = group;
        manager.unselectAllElements();
    }, [selectedElements, manager]);

    const handleClick = useCallback(() => {
        if (selectedElements[0] instanceof Group && selectedElements.length === 1) return handleEdit();
        else handleCreateGroup();
    }, [selectedElements, handleCreateGroup, handleEdit]);

    return (
        <div
            id="edit_group"
            className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': disabled })}
            onClick={handleClick}
        >
            <CreateEditGroupOfSimilarSVG />
            <div>CREATE/EDIT<br />GROUP OF SIMILAR</div>
        </div>
    );
}
