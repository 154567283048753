import tinycolor from 'tinycolor2';
import { Color } from './Manager';

export function generateRandomColor(): Color {
    const color = tinycolor({ h: getRandomHue(), s: 0.55, l: 0.5 }).toRgb();
    return [color.r, color.g, color.b, color.a * 220];
}

export function parseLabelBox(labelBox: string) {
    const obj = JSON.parse(labelBox.replaceAll(`'`, `"`));
    return [obj.left, obj.top, obj.right, obj.bottom];
}

export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getRandomElement<T>(array: T[]): T {
  if (array.length === 0) {
    throw new Error("Array cannot be empty");
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}


export function createCanvasAndContext(width = 0, height = 0): { canvas: HTMLCanvasElement, context: CanvasRenderingContext2D } {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    if (!canvas) throw new Error('Canvas not exist.');
    const context = canvas.getContext('2d', { willReadFrequently: true });
    if (!context) throw new Error('Context not exist.');
    return { canvas, context };
}

function getRandomHue(): number {
    const randomAngleBefore = Math.floor(Math.random() * 195);
    const randomAngleAfter = Math.floor(Math.random() * (360 - 265) + 266);

    return (Math.random() < 0.5 ? randomAngleBefore : randomAngleAfter);
}

export function normalizeSize(
    imageUrl: string,
    targetWidth: number,
    targetHeight: number
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "*";
      img.src = imageUrl;
      
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) return reject("Canvas not supported");
  
        const offsetX = (targetWidth - img.width) / 2;
        const offsetY = (targetHeight - img.height) / 2;

        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, targetWidth, targetHeight);
        ctx.drawImage(img, offsetX, offsetY, img.width, img.height);
  
        resolve(canvas.toDataURL("image/png"));
      };
      
      img.onerror = () => reject("Error loading image");
    });
  }