import { useCallback, useEffect, useState } from 'react';
import config from 'config';
import Input from 'Components/Input';
import Button from 'Components/Button';
import Checkbox from 'Components/Checkbox';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { Step, magic } from '../Auth';
import { magicAuthFx } from 'entities/user';
import './SignIn.scss';


type SignInProps = {
    setStep: (value: Step) => void;
};

export default function SignIn({ setStep }: SignInProps) {
    const [email, setEmail] = useState('');
    const [keepSignedIn, setKeepSignedIn] = useState(false);

    const onClickGoogle = useCallback(() => {
        const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');
        url.searchParams.append('response_type', 'code');
        url.searchParams.append('client_id', config.googleId);
        url.searchParams.append('redirect_uri', window.location.origin);
        url.searchParams.append('scope', 'email');
        url.searchParams.append('state', JSON.stringify({}));
        url.searchParams.append('prompt', 'select_account');

        window.location.href = url.toString();
    }, []);

    useEffect(() => localStorage.setItem('keepSignedIn', String(keepSignedIn)), [keepSignedIn]);

    const signInWithMagic = useCallback(async () => {
        const token = await magic.auth.loginWithMagicLink({ email });
        if (!token) throw new Error('Token not exist.');
        magicAuthFx({ token });
    }, [email]);

    return (
        <div className="sign-in">
            <div className="sign-in__title-container">
                <div className="sign-in__title">Sign in</div>
            </div>
            <div className="sign-in__form-container">
                <div className="sign-in__form-gmail" onClick={onClickGoogle}>
                    <div className="sign-in__form-gmail-icon" />
                    <div className="sign-in__form-gmail-text">SIGN IN WITH GOOGLE</div>
                </div>
                <div className="sign-in__form-title">Or sign in with your email</div>
                <div className="sign-in__form-label">Email</div>
                <Input className="sign-in__form" value={email} onChange={e => setEmail(e.currentTarget.value)} placeholder="Enter your email" />
                <Button size="medium" color="white" disabled={!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)} className="sign-in__form-button" onClick={signInWithMagic}>SIGN IN</Button>
                <div className="sign-in__form-checkbox-form">
                    <Checkbox checked={keepSignedIn} onChange={setKeepSignedIn} className='sign-in__form-checkbox' />
                    <div className="sign-in__form-checkbox-label">Keep me signed in</div>
                </div>
                <div className="sign-in__form-sign-up">New to io-buro? <span className="sign-in__form-sign-up-create-link" onClick={() => setStep(Step.SIGN_UP_STEP_0)}>Create account</span></div>
            </div>
            <div className="sign-in__exit-button-container">
                <div className="sign-in__exit-button" onClick={() => setLeftSidePanel(LeftSidePanel.NONE)} />
            </div>
        </div>
    );
}
