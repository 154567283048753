import { useStore } from 'effector-react';
import { $modal, ModalType } from 'entities/modal';
import { $route, RoutePath } from 'entities/router';
import { $userId } from 'entities/user';
import { $leftSidePanel, LeftSidePanel } from 'entities/leftSidePanel';
import Sketch from './Sketch';
import Auth from './Auth';
import Home from './Home';
import SubmitForRendering from './SubmitForRendering';
import Account from './Account';
import Notification from 'Components/Notification';
import { $hint } from 'entities/hint';
import Hint from 'Components/Hint/Hint';
import Share from 'Components/Share';
import SaveBeforeClosing from './leftSidePanel/SaveBeforeClosing';
import Help from 'Components/Help';
import Saving from './Account/PersonalSettings/Saving';
import IrreversibleStep from './leftSidePanel/IrreversibleStep';
import { $shadow } from 'entities/everything';
import Shadow from './Shadow';
import { $tooltip } from 'entities/rooltip';
import { createElement } from 'react';


export default function Router() {
    const userId = useStore($userId);
    const route = useStore($route);
    const modal = useStore($modal);
    const leftSidePanel = useStore($leftSidePanel);
    const shadow = useStore($shadow);
    const hint = useStore($hint);
    const tooltip = useStore($tooltip);

    if (!userId) return null;

    return (
        <>
            {leftSidePanel === LeftSidePanel.AUTH && <Auth />}
            {leftSidePanel === LeftSidePanel.IRREVERSIBLE_STEP && <IrreversibleStep />}
            {leftSidePanel === LeftSidePanel.ACCOUNT && <Account />}
            {leftSidePanel === LeftSidePanel.SUBMIT_FOR_RENDERING && <SubmitForRendering />}
            {leftSidePanel === LeftSidePanel.SAVE_BEFORE_CLOSING && <SaveBeforeClosing />}

            {route === RoutePath.HOME && <Home />}
            {route === RoutePath.SKETCH && <Sketch />}

            {modal.type === ModalType.SHARE && <Share {...modal.props} />}
            {tooltip && <>{createElement(tooltip.element, { parent: tooltip.parent })}</>}

            <Saving />

            <Notification />
            {shadow && <Shadow />}
            {hint && <Hint hint={hint} />}
            <Help />
        </>
    );
}
