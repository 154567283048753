import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as MinusSVG } from './icons/minus.svg';
import { ReactComponent as PlusSVG } from './icons/plus.svg';
import { ReactComponent as PrototypeSVG } from './icons/prototype.svg';
import { ReactComponent as PrototypeChangedSVG } from './icons/prototype_changed.svg';
import { ReactComponent as ArrowSVG } from './icons/arrow.svg';
import { ReactComponent as RandomSVG } from './icons/random.svg';
import Qualities from './Qualities';
import Button from 'Components/Button';
import Environment from './Environment';
import { ManagerInitStep, useInitiator, useManager } from 'entities/sketch/Manager';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import Note from './Note';
import { DownloadFormTemplate, useUploader } from 'entities/sketch/Uploader';
import NoteIdentifyButton from './NoteIdentifyButton';
import SelectForm from 'Components/SelectForm';
import Checkbox from 'Components/Checkbox';
import NoteIdentifyButtonWithTooltip from './NoteIdentifyButtonWithTooltip';
import './DownloadForm.scss';


enum Tab {
    QUALITIES = 'QUALITIES',
    ENVIRONMENT = 'ENVIRONMENT',
}

export default function DownloadForm() {
    const { uploader } = useManager('self');
    const checked = useUploader('checked');
    const form = useUploader('state');
    const initStep = useInitiator('initStep');
    const [tab, setTab] = useState(Tab.QUALITIES);

    const qualitiesErrored = useMemo(() => checked && (['materialDiversity', 'prevailingMaterials', 'dominantTextures', 'dominantSurfaces', 'detailIntegration', 'paletteDiversity', 'paletteTemperature', 'primaryTone', 'secondaryTone'] as Array<keyof DownloadFormTemplate>).some(key => !form[key]), [checked, form]);
    const environmentErrored = useMemo(() => checked && (['time', 'season', 'weather', 'scenery', 'country'] as Array<keyof DownloadFormTemplate>).some(key => !form[key]), [checked, form]);

    useEffect(() => uploader.refreshState(), [uploader]);

    return (
        <div className="download-form">
            <NoteFrame setTab={setTab} />
            <div className={classNames({ 'download-form__global-forms': true, disabled: initStep === ManagerInitStep.PROTOTYPING })}>
                <div className="download-form__accuracy-form" id="accuracy">
                    <div className="download-form__accuracy-form-title">INPUT DESIGN IMAGE ACCURACY:</div>
                    <SelectForm className="download-form__accuracy-form-select" options={['LOOSE', 'EXACT']} state={form.accuracy} setState={value => uploader.state = { ...form, accuracy: value }} checked={false} placeholder="" />
                </div>
                <div className="download-form__scene-form" id="the_scene_type">
                    <div className="download-form__scene-form-title">THE SCENE TYPE:</div>
                    <SelectForm className="download-form__scene-form-select" options={['EXTERIOR', 'INTERIOR']} state={form.spaceType} setState={value => uploader.state = { ...form, spaceType: value }} checked={false} placeholder="" />
                </div>
            </div>
            <div id="materials-and-context" className="download-form__materials-and-context">
                <div className="download-form__top-bar">
                    <div className={classNames({ 'download-form__top-bar-option': true, 'download-form__top-bar-option_selected': tab === Tab.QUALITIES, 'download-form__top-bar-option_errored': qualitiesErrored })} onClick={() => setTab(Tab.QUALITIES)}>GENERALIZED MATERIAL QUALITIES</div>
                    <div className="download-form__top-bar-gap" />
                    <div className={classNames({ 'download-form__top-bar-option': true, 'download-form__top-bar-option_selected': tab === Tab.ENVIRONMENT, 'download-form__top-bar-option_errored': environmentErrored })} onClick={() => setTab(Tab.ENVIRONMENT)}>ENVIRONMENT AND TIME</div>
                    <div className="download-form__top-bar-border" />
                    <div className={classNames({ 'download-form__top-bar-random-button': true, disabled: initStep === ManagerInitStep.PROTOTYPING })} onClick={() => uploader.applyRandomSettings()}>
                        <RandomSVG className="download-form__top-bar-random-button-icon" />
                        <div>APPLY RANDOM SETTINGS</div>
                    </div>
                </div>
                {tab === Tab.QUALITIES && <Qualities />}
                {tab === Tab.ENVIRONMENT && <Environment />}
            </div>
            <div className="download-form__footer">
                <div id="prototype" className="download-form__footer-high-light-button">
                    <PrototypeButton />
                </div>
                <div id="proceed_button" className="download-form__footer-rezognize-high-light-button">
                    <Button disabled={initStep !== ManagerInitStep.PROTOTYPE_LOADED} size="medium" color="white" className="download-form__footer-big-black-button" onClick={() => setLeftSidePanel(LeftSidePanel.IRREVERSIBLE_STEP)}>
                        <div>PROCEED TO REFINE</div>
                        <ArrowSVG />
                    </Button>
                </div>
            </div>
        </div>
    );
}


function PrototypeButton() {
    const { uploader } = useManager('self');
    const state = useUploader('state');
    const prototypeState = useUploader('prototypeState');
    const prototypeNumber = useUploader('prototypeNumber');
    const initStep = useInitiator('initStep');

    const stateChanged = useMemo(() => Boolean(prototypeState) && prototypeNumber && Object.entries(state).some(([key, value]) => prototypeState && (prototypeState[key as keyof DownloadFormTemplate] !== value)), [state, prototypeState, prototypeNumber]);

    if (initStep === ManagerInitStep.PROTOTYPING) return (
        <Button size="medium" color="dark" onClick={() => uploader.cancelPrototyping()}>CANCEL PROTOTYPING</Button>
    );

    return (
        <Button size="medium" color="dark" onClick={() => uploader.createPrototype()} className="download-form__footer-prototype-button">
            {
                stateChanged
                    ?
                    <PrototypeChangedSVG />
                    :
                    <PrototypeSVG />
            }
            <span>PROTOTYPE</span>
        </Button>
    );
}

type NoteFrameProps = {
    setTab: (tab: Tab) => void;
};

function NoteFrame({ setTab }: NoteFrameProps) {
    const [tooltip, setTooltip] = useState<boolean>(() => JSON.parse(localStorage.getItem('noteFrameTooltip') || 'true'));
    const [opened, setOpened] = useState(true);

    useEffect(() => {
        const video = document.getElementById('assistant_icon');
        if (!(video instanceof HTMLVideoElement)) throw new Error('Video element not found');
        let interval: NodeJS.Timer | null = null;
        video.oncanplay = () => {
            interval = setInterval(() => {
                video.currentTime = 0;
                video.play().catch(() => null);
            }, 2000 + video.duration * 1000);
        };

        return () => {
            video.oncanplay = null;
            if (interval) clearInterval(interval);
        };
    }, []);

    return (
        <div className={classNames({ 'download-form__note': true, 'download-form__note_opened': opened })}>
            {tooltip && <NoteFrameTooltip hide={() => setTooltip(false)} />}
            <video id="assistant_icon" autoPlay playsInline className="download-form__note-icon">
                <source src="/videos/assistant.mp4" type="video/mp4" />
            </video>
            {
                opened
                    ?
                    <div className="download-form__note-text">
                        <div><Note id="drawing">Load</Note> a design image and <Note id="the_scene_type">define the scene type</Note>, <Note id="accuracy">input accuracy</Note>, <Note id="materials-and-context" fn={() => setTab(Tab.QUALITIES)}>design materials</Note>, <Note id="materials-and-context" fn={() => setTab(Tab.ENVIRONMENT)}>and context</Note>.</div>
                        <div><Note id="prototype">Prototype</Note> until my interpretation aligns with your composition. Use <Note id="undo_redo">undo/redo</Note> to navigate attempts. When ready, <Note id="proceed_button">proceed to refinement</Note> by <NoteIdentifyButton>identifying specific objects</NoteIdentifyButton> to adjust and bring your vision to life.</div>
                        <div>Hover over buttons for <NoteIdentifyButtonWithTooltip>tooltips</NoteIdentifyButtonWithTooltip>, or check the <Note id="help">Help</Note> section for more details.</div>
                    </div>
                    :
                    <div className="download-form__note-text">
                        <div>Set up your prototype! </div>
                    </div>
            }
            {
                opened
                    ?
                    <MinusSVG className="download-form__note-resize" onClick={() => setOpened(false)} />
                    :
                    <PlusSVG className="download-form__note-resize" onClick={() => setOpened(true)} />
            }

        </div>
    );
}

type NoteFrameTooltipProps = {
    hide: () => void;
};

function NoteFrameTooltip({ hide }: NoteFrameTooltipProps) {
    const [neverShowAgain, setNeverShowAgain] = useState(true);

    useEffect(() => {
        if (neverShowAgain) localStorage.setItem('noteFrameTooltip', 'false');
        else localStorage.setItem('noteFrameTooltip', 'true');
    }, [neverShowAgain]);

    return (
        <div className="download-form__note-tooltip">
            <div className="download-form__note-tooltip-arrow" />
            <div className="download-form__note-tooltip-text">
                <div>HOVER THE UNDERLINED</div>
                <div>WORDS FOR HINTS</div>
            </div>
            <div className="download-form__note-tooltip-bottom">
                <Button size="small" color="white" onClick={hide}>OK</Button>
                <Checkbox checked={neverShowAgain} onChange={(value) => setNeverShowAgain(value)} className="download-form__note-tooltip-bottom-checkbox" />
                <div className="download-form__note-tooltip-checkbox-label">Never show again</div>
            </div>
        </div>
    );
}
