import { createStore } from 'effector';

export enum RoutePath {
    HOME = '/',
    SKETCH = '/sketch',
    RESULT = '/result',
}

export enum RightSide {
    NONE = 'NONE',
    LIST = 'LIST',
    DOWNLOAD_FORM = 'DOWNLOAD_FORM',
}

export const $route = createStore<RoutePath>(RoutePath.HOME);
export const $rightSide = createStore<RightSide>(RightSide.NONE);
