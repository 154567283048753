import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowSVG } from './icons/arrow.svg';
import './ColorForm.scss';

const colors = [
    '#FFFFFF',
    '#808080',
    '#708090',
    '#36454F',
    '#000000',
    '#FF0000',
    '#FFA500',
    '#FFFF00',
    '#008000',
    '#ADD8E6',
    '#0000FF',
    '#800080',
    '#B87333',
    '#A8A9AD',
    '#C0C0C0',
    '#F5F5DC',
    '#D2B48C',
    '#D2691E',
    '#654321',
    '#D6CBB5',
    '#C8BD93',
    '#B7E3B1',
    '#355E3B',
    '#5B7C99',
];

type ColorFormProps = {
    placeholder: string;
    state: string;
    setState: (value: string) => void;
    checked: boolean;
};

export default function ColorForm({ placeholder, state, setState, checked }: ColorFormProps) {
    const [opened, setOpened] = useState(false);
    const [errored, setErrored] = useState(false);

    const select = useCallback((option: string) => setState(option), [setState]);

    useEffect(() => void (checked && setErrored(!state)), [checked, state]);

    return (
        <div className="color-form">
            <div className={classNames({ 'color-form__input': true, 'color-form__input_errored': errored })} onClick={() => setOpened(prev => !prev)}>
                {!state && <div className="color-form__placeholder">{placeholder}</div>}
                {state && <div className="color-form__value" style={{ backgroundColor: state }} />}
                <div className="color-form__arrow-container">
                    <ArrowSVG className={classNames({ pointer: true, 'color-form__arrow_rotated': opened })} />
                </div>
                {
                    opened
                    &&
                    <div className="color-form__options">
                        {colors.map(option => (<div className="color-form__option" onClick={() => select(option)} key={option} style={{ backgroundColor: option }} />))}
                    </div>
                }
            </div>
            <div className={classNames({ 'color-form__error': true, 'color-form__error_visible': errored })}>Please select an option for this field</div>
        </div>
    );
}