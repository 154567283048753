import * as R from 'ramda';
import { produce } from 'immer';
import { createEffect, createEvent, createStore } from 'effector';
import { Render, Sketch, copySketch, deleteSketch, loadRenders, loadSketches } from 'services/sketch';

export const $sketches = createStore<Array<Sketch>>([]);
export const $renders = createStore<Array<Render>>([]);

export const loadSketchesFx = createEffect(loadSketches);
export const loadRendersFx = createEffect(loadRenders);
export const deleteSketchFx = createEffect(deleteSketch);
export const copySketchFx = createEffect(copySketch);

export const updateSketchNameEvent = createEvent<{ id: string, name: string }>();

$sketches
    .on(loadSketchesFx.doneData, R.nthArg(1))
    .on(deleteSketchFx.done, (state, payload) => state.filter(sketch => sketch.id !== payload.params))
    .on(updateSketchNameEvent, (state, payload) => produce(state, draft => {
        const sketch = draft.find(sketch => sketch.id === payload.id);
        if (sketch) sketch.name = payload.name;
    }))
    .on(copySketchFx.doneData, (state, payload) => [...state, payload]);

$renders
    .on(loadRendersFx.doneData, R.nthArg(1));
