import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import * as Listeners from '../Listeners';
import { ReactComponent as PlusSVG } from './icons/plus.svg';
import { ReactComponent as CloseSVG } from './icons/close.svg';
import { ReactComponent as MinusSVG } from './icons/minus.svg';
import { ReactComponent as SpinSVG } from './icons/spin.svg';
import { ReactComponent as CheckSVG } from './icons/check.svg';
import { ReactComponent as CircleSVG } from './icons/circle.svg';
import { ReactComponent as SpinerSVG } from './icons/spiner.svg';
import { Group, ManagerInitStep, Segment, useEditor, useInitiator, useManager } from 'entities/sketch/Manager';
import useStatusBar from './hooks/useStatusBar';
import { setHint } from 'entities/hint';
import useCursor from './hooks/useCursos';
import { $modal, ModalType } from 'entities/modal';
import { $elementForAttach } from 'entities/library';
import { Notifications, setNotification } from 'entities/notification';
import { DownloadFormTemplate, useUploader } from 'entities/sketch/Uploader';
import Button from 'Components/Button';
import './Drawing.scss';


type DrawingProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
};

export default function Drawing({ listener, setListener }: DrawingProps) {
    const ref = useRef<HTMLDivElement | null>(null);
    const [size, setSize] = useState<{ width: 'auto' | number, height: number }>({ width: 'auto', height: 300 });
    const [grabbing, setGrabbing] = useState(false);
    const [ctrl, setCtrl] = useState(false);
    const [shift, setShift] = useState(false);
    const modal = useStore($modal);
    const elementForAttach = useStore($elementForAttach);
    const manager = useManager('self');
    const initStep = useInitiator('initStep');
    const selectedBackground = useManager('selectedBackground');
    const deltaFrame = useManager('deltaFrame');

    useCursor(listener);

    useEffect(() => {
        const canvas = document.getElementById('drawing__container');
        if (!canvas) return;
        let prev: Listeners.Listener | undefined;

        function onMouseDown(e: MouseEvent) {
            if (e.button !== 1) return;
            setListener(value => {
                prev = value;
                return new Listeners.Zoom(manager);
            });
            listener.onMouseDown(e);
        }

        function onMouseUp() {
            if (!prev) return;
            setListener(prev);
            prev = undefined;
            listener.onMouseLeave();
        }

        canvas.addEventListener('mousedown', onMouseDown);
        canvas.addEventListener('mouseup', onMouseUp);
        canvas.addEventListener('mouseleave', onMouseUp);
        return () => {
            canvas.removeEventListener('mousedown', onMouseDown);
            canvas.removeEventListener('mouseup', onMouseUp);
            canvas.removeEventListener('mouseleave', onMouseUp);
        };
    }, [manager, listener, setListener]);

    const { startDrag, endDrag } = useMemo(() => {
        let prev: Listeners.Listener | undefined;

        function startDrag(e?: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) {
            setListener(value => {
                if (value instanceof Listeners.Zoom) return value;
                prev = value;
                const next = new Listeners.Zoom(manager);
                if (e) next.onMouseDown(e);
                return next;
            });
        }

        function endDrag() {
            if (!prev) return;
            setListener(prev);
            prev = undefined;
        }

        return { startDrag, endDrag };
    }, [manager, setListener]);

    useEffect(() => {
        function start(e: KeyboardEvent) {
            if (e.code === 'Space' && document.activeElement instanceof HTMLBodyElement) startDrag();
        }

        function end(e: KeyboardEvent) {
            if (e.code === 'Space') endDrag();
        }

        document.addEventListener('keydown', start);
        document.addEventListener('keyup', end);

        return () => {
            document.removeEventListener('keydown', start);
            document.removeEventListener('keyup', end);
        };
    }, [startDrag, endDrag]);

    useEffect(() => {
        if (modal.type === ModalType.NONE && !elementForAttach) {
            function fn(e: KeyboardEvent) {
                if (e.code !== 'Escape') return;

                manager.unselectAllElements();
            }

            document.addEventListener('keydown', fn);
            return () => document.removeEventListener('keydown', fn);
        }
    }, [modal, manager, elementForAttach]);

    useEffect(() => {
        const handleKeydown = (e: KeyboardEvent) => {
            if (listener instanceof Listeners.Select && e.key === 'Tab') {
                e.preventDefault();
                listener.handleTab();
            }
        }

        document.addEventListener('keydown', handleKeydown);

        return () => document.removeEventListener('keydown', handleKeydown);
    }, [listener]);

    useEffect(() => {
        const canvas = document.getElementById('canvas');
        if (!(canvas instanceof HTMLCanvasElement)) throw new Error('canvas not canvas =)');
        if (!manager) throw new Error('Segments not exist');
        manager.connectCanvas(canvas);
    }, [manager]);

    useEffect(() => {
        const container = ref.current;
        if (!container) throw new Error('Container not exist.');

        const resizeObserver = new ResizeObserver(e => setSize({ width: e[0].contentRect.width, height: e[0].contentRect.height }));
        resizeObserver.observe(container);

        return () => resizeObserver.unobserve(container);
    }, []);

    useEffect(() => {
        if (!(listener instanceof Listeners.BrushToolListener)) return;

        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "[") manager.changeCursorRadius(-1);

            if (event.key === "]") manager.changeCursorRadius(1);
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => window.removeEventListener("keydown", handleKeyPress);
    }, [listener, manager]);

    useEffect(() => {
        if (!(listener instanceof Listeners.BrushToolListener)) manager.setCursor(null);
    }, [listener, manager]);

    useEffect(() => {
        if (!(listener instanceof Listeners.Zoom)) setGrabbing(false);
        else listener.setGrabbing = setGrabbing;
    }, [listener]);

    useEffect(() => {
        function down(event: KeyboardEvent) {
            if (event.ctrlKey || event.metaKey) setCtrl(true);
            if (event.shiftKey) setShift(true);
        }
        function up(event: KeyboardEvent) {
            if (!event.ctrlKey && !event.metaKey) setCtrl(false);
            if (!event.shiftKey) setShift(false);
        }

        document.addEventListener('keydown', down);
        document.addEventListener('keyup', up);

        return () => {
            document.removeEventListener('keydown', down);
            document.removeEventListener('keyup', up);
        }
    }, []);

    const onMouseDown = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) => {
        if (e.button !== 1) return listener.onMouseDown(e);

        startDrag(e);
    }, [listener, startDrag]);

    const onMouseUp = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (e.button !== 1) return listener.onMouseUp(e);

        endDrag();
    }, [listener, endDrag]);

    const onMouseClick = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (e.button !== 1) return listener.onClick(e);
    }, [listener]);

    return (
        <div id="drawing" className="drawing" ref={ref} >
            {initStep === ManagerInitStep.CREATED && <UploadFrame />}
            {initStep === ManagerInitStep.PROTOTYPING && <PrototypeLoader />}
            <canvas
                id="canvas"
                className={classNames({
                    drawing__canvas: true,
                    'cursor-frame-to-add': listener instanceof Listeners.BoxListener,
                    'cursor-selection': listener instanceof Listeners.Select || (deltaFrame !== null && deltaFrame === 0),
                    'cursor-selection-add': listener instanceof Listeners.Select && ctrl && !deltaFrame,
                    'cursor-selection-remove': listener instanceof Listeners.Select && shift && !deltaFrame,
                    'cursor-cross-selection': deltaFrame !== null && deltaFrame < 0,
                    'cursor-cross-selection-add': deltaFrame !== null && deltaFrame < 0 && ctrl,
                    'cursor-cross-selection-remove': deltaFrame !== null && deltaFrame < 0 && shift,
                    'cursor-window-selection': deltaFrame !== null && deltaFrame > 0,
                    'cursor-window-selection-add': deltaFrame !== null && deltaFrame > 0 && ctrl,
                    'cursor-window-selection-remove': deltaFrame !== null && deltaFrame > 0 && shift,
                    'cursor-add': listener instanceof Listeners.PositivePoint,
                    'cursor-remove': listener instanceof Listeners.NegativePoint,
                    'cursor-brush-tool': listener instanceof Listeners.BrushToolListener,
                    'cursor-grab': listener instanceof Listeners.Zoom,
                    'cursor-grabbing': grabbing,
                })}
                onWheel={manager.scale}
                onMouseDown={e => onMouseDown(e)}
                onMouseMove={e => listener.onMouseMove(e)}
                onMouseUp={e => onMouseUp(e)}
                onClick={e => onMouseClick(e)}
                onDoubleClick={e => listener.onDoubleClick(e)}
                onMouseLeave={() => listener.onMouseLeave()}
                {...size}
            />
            <div className="drawing__footer">
                {initStep !== ManagerInitStep.CREATED && <div className="drawing__footer-bar">
                    <div className="drawing__footer-bar-left">
                        <div className={classNames({ 'drawing__footer-background-selector': true, 'drawing__footer-background-selector_disabled': initStep < ManagerInitStep.PROTOTYPE_LOADED })}>
                            <div className={classNames({ 'drawing__footer-background-option': true, 'drawing__footer-background-option_selected': selectedBackground === 'input' })} onClick={() => manager.selectBackground('input')}>
                                {selectedBackground === 'input' && <CheckSVG />}
                                <div className="drawing__footer-background-option-text">Input</div>
                            </div>
                            <div className={classNames({ 'drawing__footer-background-option': true, 'drawing__footer-background-option_selected': selectedBackground === 'prototype' })} onClick={() => manager.selectBackground('prototype')}>
                                {selectedBackground === 'prototype' && <CheckSVG />}
                                <div className="drawing__footer-background-option-text">Prototype</div>
                            </div>
                            {initStep >= ManagerInitStep.RECOGNIZING && <div className={classNames({ 'drawing__footer-background-option': true, 'drawing__footer-background-option_selected': selectedBackground === 'masks' })} onClick={() => manager.selectBackground('masks')}>
                                {selectedBackground === 'masks' && <CheckSVG />}
                                <div className="drawing__footer-background-option-text">Masks</div>
                            </div>}
                        </div>
                        {initStep < ManagerInitStep.RECOGNIZING && <FooterReplaceButton />}
                    </div >
                    <div className={classNames({ 'drawing__zoom-buttons': true, 'drawing__zoom-buttons_disabled': initStep === ManagerInitStep.INPUT_LOADED })} id="drawing__zoom-buttons">
                        <div
                            className="drawing__zoom-minus"
                            onClick={() => manager.scaleFromButton(1)}
                            id="zoom_in"
                            onMouseEnter={() => setHint({ id: 'zoom_in' })}
                            onMouseLeave={() => setHint(null)}
                        >
                            <MinusSVG />
                        </div>
                        <div
                            id="zoom_out"
                            className="drawing__zoom-plus"
                            onClick={() => manager.scaleFromButton(-1)}
                            onMouseEnter={() => setHint({ id: 'zoom_out' })}
                            onMouseLeave={() => setHint(null)}
                        >
                            <PlusSVG />
                        </div>
                        <div
                            id="zoom_fit"
                            className="drawing__zoom-fit"
                            onClick={() => manager.fit()}
                            onMouseEnter={() => setHint({ id: 'zoom_fit' })}
                            onMouseLeave={() => setHint(null)}
                        >
                            <span>FIT</span>
                        </div>
                    </div>
                </div >}
                {initStep <= ManagerInitStep.INPUT_LOADED && <InputingFooterState />}
                {initStep === ManagerInitStep.PROTOTYPING && <PrototypingFooterState />}
                {initStep === ManagerInitStep.PROTOTYPE_LOADED && <PrototypedFooterState />}
                {initStep === ManagerInitStep.RECOGNIZING && <RecognizingFooterState />}
                {initStep === ManagerInitStep.READY && <FooterState listener={listener} />}
            </div>
        </div >
    );
}

function FooterReplaceButton() {
    const { initiator } = useManager('self');
    const fileName = useInitiator('fileName');
    const initStep = useInitiator('initStep');

    if (initStep === ManagerInitStep.CREATED) return null;

    return (
        <div className="drawing__footer-replace-button-container">
            <div className="drawing__footer-replace-button-file-name">{fileName.split('.').slice(0, -1).join('.') + '.'}</div>
            <div className="drawing__footer-replace-button-file-ext">{fileName.split('.').at(-1)}</div>
            <CloseSVG className="drawing__footer-replace-button-file-close-button" onClick={() => initiator.clearSource()} />
        </div>
    );
}

function PrototypeLoader() {
    return (
        <div className="drawing__prototype-loader">
            <div className="drawing__prototype-loader-spinner" />
        </div>
    );
}

function UploadFrame() {
    const manager = useManager('self');
    const checked = useUploader('checked');
    const initStep = useInitiator('initStep');
    const [isDragging, setIsDragging] = useState(false);

    const checkEndSetFile = useCallback((file: File | undefined) => {
        if (!file) throw new Error('File not exist.');
        if (initStep === ManagerInitStep.PROTOTYPING) return;

        const maxSize = 36 * 1024 * 1024;
        if (!file) throw new Error('File not exist.');
        if (file.size > maxSize) return setNotification(Notifications.UPLOAD_SIZE_ERROR);
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) return setNotification(Notifications.UPLOAD_EXT_ERROR);
        setNotification(null);

        manager.initiator.applySource(file);
    }, [manager, initStep]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) return console.error('Files not exist.');
        const file = files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleUpload = useCallback(() => {
        const input = document.getElementById('input');
        if (!input) throw new Error('Input not found.');
        input.click();
    }, []);

    const useSample = useCallback(async () => {
        const sampleDiv = document.getElementById('upload__sample');
        if (!sampleDiv) throw new Error('Sample not found.');
        const backgroundImage = window.getComputedStyle(sampleDiv).backgroundImage;
        const imageUrl = backgroundImage.replace(/url\(["']?(.*?)["']?\)/, '$1');

        const file = await fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => new File([blob], "sample.jpg", { type: blob.type }));
        manager.initiator.applySource(file);
    }, [manager]);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        setIsDragging(false);
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const dragLeave = useCallback(() => setIsDragging(false), []);

    return (
        <div
            className={classNames({ 'upload__frame-container': true, 'upload__frame-container_dragging': isDragging })}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            onDragLeave={dragLeave}
        >
            <input id="input" onChange={handleChange} accept='image/jpeg, image/jpg, image/png' type='file' hidden />
            <div className="upload__frame">
                <div className="upload__browse" onClick={handleUpload}>
                    <div className="upload__browse-title">
                        <div>DRAG & DROP</div>
                        <div>OR</div>
                        <div>BROWSE DESIGN IMAGE TO RENDER</div>
                    </div>
                    <div className="upload__browse-button">BROWSE</div>
                    <div className="upload__browse-note">
                        <div>Only files with the following extensions</div>
                        <div>are allowed: png, jpg, jpeg.</div>
                        <div>36Mb limit, 1 file only</div>
                    </div>
                </div>
                <div className="upload__sample" id="upload__sample" onClick={useSample}>
                    <div className="upload__sample-background">
                        <div className="upload__sample-title">
                            <div>OR</div>
                            <div>USE OUR PRE-MADE SAMPLE</div>
                        </div>
                        <Button size="medium" color="dark" className="upload__sample-button">USE SAMPLE</Button>
                    </div>
                </div>
                {checked && <div className="upload__frame-checked">Please upload an image or use a sample</div>}
            </div>
        </div>
    );
}

function InputingFooterState() {
    const initStep = useInitiator('initStep');
    const state = useUploader('state');

    const formFilled = useMemo(() => Object.values(state).every(value => value), [state]);

    return (
        <div className="drawing__footer-state">
            {initStep === ManagerInitStep.CREATED && <span>To begin, load a design image and configure the settings.</span>}
            {initStep === ManagerInitStep.INPUT_LOADED && (formFilled
                ?
                <span>All set—let’s start prototyping.</span>
                :
                <span>To begin, load a design image and configure the settings.</span>)
            }
            <CircleSVG className="drawing__footer-ai-circle" />
        </div>
    );
}

function PrototypedFooterState() {
    const state = useUploader('state');
    const prototypeState = useUploader('prototypeState');
    const prototypeNumber = useUploader('prototypeNumber');
    const [text, setText] = useState('Does this feel like a strong starting point for refinement? If not, click Prototype and I’ll reinterpret.');

    const stateChanged = useMemo(() => Boolean(prototypeState) && Object.entries(state).some(([key, value]) => prototypeState && (prototypeState[key as keyof DownloadFormTemplate] !== value)), [state, prototypeState]);

    useEffect(() => {
        if (stateChanged) return setText('I see you’ve made some changes. Click Prototype to update my interpretation.');
        if (prototypeNumber > 1) return setText('Would you like to refine this or explore another take? Use undo to step back, or prototype again for a fresh interpretation.');
        return setText('Does this feel like a strong starting point for refinement? If not, click Prototype and I’ll reinterpret.');
    }, [stateChanged, prototypeNumber]);

    return (
        <div className="drawing__footer-state">
            <span>{text}</span>
            <CircleSVG className="drawing__footer-ai-circle" />
        </div>
    );
}

function RecognizingFooterState() {
    return (
        <div className="drawing__footer-state">
            <span>Breaking this down into details—get ready to fine-tune specific elements.</span>
            <SpinerSVG className="drawing__footer-ai-circle drawing__footer-ai-spin" />
        </div>
    );
}

const prototypingFooterStateTextes = [
    'Alright, let me picture this… Let’s see how I imagine it.',
    'Translating your sketch into my interpretation—give me a moment.',
    'I think I see what you’re going for… ',
    'Let’s bring your concept into focus. One moment!',
    'Trying my best to match your vision—hope I get it right!',
];

function PrototypingFooterState() {
    const [text, setText] = useState(() => prototypingFooterStateTextes[Math.floor(Math.random() * prototypingFooterStateTextes.length)]);

    const getRandomText = useCallback(() => prototypingFooterStateTextes[Math.floor(Math.random() * prototypingFooterStateTextes.length)], []);

    useEffect(() => {
        const interval = setInterval(() => setText(getRandomText()), 5000);
        return () => clearInterval(interval);
    }, [getRandomText]);

    return (
        <div className="drawing__footer-state">
            <span>{text}</span>
            <SpinerSVG className="drawing__footer-ai-circle drawing__footer-ai-spin" />
        </div>
    );
}

type FooterStateProps = {
    listener: Listeners.Listener;
};

function FooterState({ listener }: FooterStateProps) {
    const manager = useManager('self');
    const list = useManager('list');
    const status = useStatusBar(listener);
    const onnxState = useEditor('onnxState');
    const editing = useEditor('editing');
    const selectedElements = useManager('selectedElements');
    const [prevSelectedElements, setPrevSelectedElements] = useState<Array<Array<Segment | Group>>>([[], []]);

    useEffect(() => setPrevSelectedElements(prev => [prev[1], selectedElements]), [selectedElements]);
    useEffect(() => setPrevSelectedElements([[], []]), [list]);

    const reselect = useCallback(() => manager.selectElements(prevSelectedElements[0]), [manager, prevSelectedElements]);

    return (
        <div className="drawing__footer-state">
            <span>{status}</span>
            {onnxState.busy
                ?
                <div className="drawing__footer-ai-state">
                    <span>AI is drawing masks</span>
                    <SpinSVG className="drawing__footer-ai-state-spin" />
                </div>
                :
                <>{
                    Boolean(!editing)
                    &&
                    <div className="drawing__footer-reselect-container">
                        {
                            Boolean(prevSelectedElements[0].length)
                            &&
                            <div
                                id="reselect"
                                onClick={reselect}
                                className="drawing__footer-reselect"
                                onMouseEnter={() => setHint({ id: 'reselect' })}
                                onMouseLeave={() => setHint(null)}
                            >Reselect</div>
                        }
                        {
                            <div className="drawing__footer-items">Selected item(s): {selectedElements.length}</div>
                        }
                    </div>
                }</>
            }
        </div >
    );
}
