import { useCallback, useMemo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { Group as GroupClass, Segment, useEditor, useGroup, useSegment, useManager } from 'entities/sketch/Manager';
import { ReactComponent as DragHandleSVG } from './icons/drag_handle.svg';
import { ReactComponent as CheckboxSVG } from './icons/checkbox.svg';
import { ReactComponent as RenderSVG } from './icons/render.svg';
import { ReactComponent as RenderingSVG } from './icons/rendering.svg';
/* import { ReactComponent as RerenderSVG } from './icons/rerendered.svg'; */
import { ReactComponent as WaitingRenderSVG } from './icons/waiting_render.svg';
import { ReactComponent as CheckSVG } from './icons/check.svg';
import { ReactComponent as CheckboxCheckedSVG } from './icons/checkbox_ckecked.svg';
import { ReactComponent as GroupSVG } from './icons/group.svg';
import { ReactComponent as TriangleSVG } from './icons/triangle.svg';
import { ReactComponent as AddAttachSVG } from './icons/add_attach.svg';
import { ReactComponent as DisabledSVG } from './icons/disabled.svg';
import { ReactComponent as ReadOnlySVG } from './icons/read_only.svg';
import { ReactComponent as UngroupSVG } from './icons/ungroup.svg';
import Button from 'Components/Button';
import { AttachmentComponent, GroupSegmentAttachmentComponent } from '../Attachment';
import { setElementForAttach } from 'entities/library';
import { useElementHeight, useHotKey } from 'hooks';
import { Notifications, setNotification } from 'entities/notification';
import { setHint } from 'entities/hint';
import { SpriteState } from 'entities/sketch/Engine/Engine';
import './Group.scss';


type GroupProps = {
    group: GroupClass;
    index: number;
    realIndex?: number;
    matchDefinition: undefined | ((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, id?: string) => void);
};

export function EditingGroup({ group, index, matchDefinition }: GroupProps) {
    const manager = useManager('self');
    const selectedElements = useManager('selectedElements');
    const segmentsOfGroup = useGroup(group, 'segments');
    const attachments = useGroup(group, 'attachments');
    const description = useGroup(group, 'description');
    const [attachmentsIsOpened, setAttachmentsIsOpened] = useState(false);
    const [segmentsIsOpened, setSegmentsIsOpened] = useState(true);
    const [prevDescription, setPrevDescription] = useState(description);
    const segmentsElement = useRef<HTMLDivElement | null>(null);
    const attachmentsElement = useRef<HTMLDivElement | null>(null);

    const selected = useMemo(() => selectedElements.includes(group), [selectedElements, group]);

    const handleClickSegment = useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, segment: Segment) => {
        if (!e.ctrlKey && !e.metaKey) return;
        if (group.segments.includes(segment)) manager.removeSegmentFromGroup(segment, group, 0);
        else throw new Error('Group has this segment.');
    }, [group, manager]);

    useHotKey('Enter', () => manager.doneEditingGroup());

    const onMouseEnter = useCallback((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {
        if (matchDefinition && (group.description || group.attachments.length) && (!manager.matchDefinition || !manager.matchDefinition.full)) manager.matchDefinition = { element: group, full: false, x: e.clientX, y: e.clientY };
        else manager.hoveredElements = [group];
    }, [group, manager, matchDefinition]);

    const onMouseLeave = useCallback(() => {
        if (manager.matchDefinition && !manager.matchDefinition.full && manager.matchDefinition.element === group) manager.matchDefinition = null;
        if (manager.hoveredElements[0] === group) manager.hoveredElements = [];
    }, [group, manager]);

    const onBlur = useCallback(() => {
        if (prevDescription === description) return;
        else manager.saveSketch();
    }, [manager, prevDescription, description]);

    const inputFocus = useCallback(() => {
        const input = document.getElementById(`group_${group.id}_input`);
        if (!input) throw new Error('Input not exist.');
        input.focus();
    }, [group]);

    const [segmentsHeight, openSegmentsHeight, closeSegmentsHeight] = useElementHeight(segmentsElement, 'auto');
    const [attachmentsHeignt, openAttachmentsHeignt, closeAttachmentsHeignt] = useElementHeight(attachmentsElement);

    const openSegments = useCallback(() => {
        if (!segmentsOfGroup.length) return;
        if (attachmentsIsOpened) setAttachmentsIsOpened(prev => {
            if (prev) closeAttachmentsHeignt();
            else openAttachmentsHeignt();
            return !prev;
        });

        setSegmentsIsOpened(prev => {
            if (prev) closeSegmentsHeight();
            else openSegmentsHeight();
            return !prev;
        });
    }, [attachmentsIsOpened, segmentsOfGroup, openSegmentsHeight, closeSegmentsHeight, openAttachmentsHeignt, closeAttachmentsHeignt]);

    const openAttachments = useCallback(() => {
        if (segmentsIsOpened) setSegmentsIsOpened(prev => {
            if (prev) closeSegmentsHeight();
            else openSegmentsHeight();
            return !prev;
        });

        setAttachmentsIsOpened(prev => {
            if (prev) closeAttachmentsHeignt();
            else openAttachmentsHeignt();
            return !prev;
        });
    }, [segmentsIsOpened, openAttachmentsHeignt, closeAttachmentsHeignt, openSegmentsHeight, closeSegmentsHeight]);

    return (
        <>
            <Draggable draggableId={group.id} index={index} isDragDisabled>
                {(providedGroup, snapshotGroup) => (
                    <div {...providedGroup.draggableProps} ref={providedGroup.innerRef}>
                        <div
                            id={'element_' + group.id}
                            className={classNames({ group: true, 'group_is-being-dragged': snapshotGroup.isDragging, group_active: selected, 'group_border-bottom': !segmentsIsOpened })}
                            onClick={e => matchDefinition && matchDefinition(e, group.id)}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        >
                            <div className="group__cell1">
                                <div {...providedGroup.dragHandleProps} className="group__drag-handler-container" tabIndex={-1}>
                                    <DragHandleSVG />
                                </div>
                                <CheckboxSVG className="pointer" />
                            </div>
                            <div className="group__cell2">
                                <div className={classNames({ 'group__open-segments': true, 'group__open-segments_disabled': !segmentsOfGroup.length })} onClick={openSegments}>
                                    <GroupSVG />
                                    <TriangleSVG className={classNames({ 'group__open-button': true, 'group__open-button_opened': segmentsIsOpened })} />
                                </div>
                            </div>
                            <div className="group__cell3" onClick={inputFocus}>
                                <TextareaAutosize
                                    maxRows={4}
                                    id={`group_${group.id}_input`}
                                    className="group__description"
                                    placeholder="Type definition for the group of similar objects."
                                    value={description}
                                    onChange={e => group.description = e.currentTarget.value}
                                    onFocus={() => setPrevDescription(description)}
                                    onBlur={onBlur}
                                />
                            </div>
                            <div className="group__cell4">
                                {
                                    Boolean(attachments.length)
                                    &&
                                    <div className="group__open-attachments" onClick={openAttachments}>
                                        <span>{attachments.length}</span>
                                        <TriangleSVG className={classNames({ 'group__cell4-triangle': true, 'group__cell4-triangle_opened': attachmentsIsOpened })} />
                                    </div>
                                }
                            </div>
                            <div className="group__cell5">
                                <div className="group__add-attach-icon-container" onClick={() => { setNotification(Notifications.MORE_ATTACHMENTS_INFO); setElementForAttach(group); }}>
                                    <AddAttachSVG />
                                </div>
                            </div>
                            <div className="group__cell6">
                                <div className="group__ungroup-icon-container" onClick={() => manager.ungroup([group])} data-type="action">
                                    <UngroupSVG />
                                </div>
                            </div>
                            {
                                segmentsIsOpened
                                &&
                                <>
                                    <div className="group__bottom_cell" />
                                    <div className="group__bottom_cell" />
                                    <div className="group__bottom_cell" />
                                    <div className="group__cell9 group__bottom_cell">
                                        <div className="group__approve">
                                            <Button className="group__done-button" size="small" color="dark" onClick={() => manager.doneEditingGroup()}>
                                                <CheckSVG />
                                                <div>DONE</div>
                                            </Button>
                                            <Button className="group__approve-cancel-button" size="small" color="white" onClick={() => manager.cancelEditingGroup()}>CANCEL</Button>
                                        </div>
                                    </div>
                                    <div className="group__bottom_cell" />
                                    <div className="group__bottom_cell" />
                                    <div className="group__bottom_cell" />
                                </>
                            }
                        </div>
                        <div className="group__attachments" ref={attachmentsElement} style={{ height: attachmentsHeignt }}>
                            {attachments.map(attachment => <AttachmentComponent element={group} attachment={attachment} key={attachment.id} />)}
                        </div>
                    </div>
                )}
            </Draggable >
            <div className="segments-of-group" ref={segmentsElement} style={{ height: segmentsHeight }}>
                {segmentsOfGroup.map((segment, i) =>
                (<Draggable draggableId={segment.id} index={index + 1 + i} key={segment.id}>
                    {(providedGroup, snapshotSegmentGroup) => (
                        <div {...providedGroup.draggableProps} ref={providedGroup.innerRef} onClick={e => handleClickSegment(e, segment)}>
                            <GroupSegmentElement
                                matchDefinition={matchDefinition}
                                segment={segment}
                                group={group}
                                dragHandle={providedGroup.dragHandleProps}
                                isDragging={snapshotSegmentGroup.isDragging}
                                index={i}
                            />
                        </div>
                    )}
                </Draggable>)
                )}
            </div>
        </>
    );
}

export function Group({ group, index, realIndex, matchDefinition }: GroupProps) {
    const editingGroup = useManager('editingGroup');
    const segmentsOfGroup = useGroup(group, 'segments');
    const description = useGroup(group, 'description');
    const attachments = useGroup(group, 'attachments');
    const editing = useEditor('editing');
    const manager = useManager('self');
    const selectedElemets = useManager('selectedElements');
    const [attachmentsIsOpened, setAttachmentsIsOpened] = useState(false);
    const [segmentsIsOpened, setSegmentsIsOpened] = useState(false);
    const [prevDescription, setPrevDescription] = useState(description);
    const segmentsElement = useRef<HTMLDivElement | null>(null);
    const attachmentsElement = useRef<HTMLDivElement | null>(null);
    const inpainting = useGroup(group, 'inpainting');

    const selected = useMemo(() => selectedElemets.includes(group), [selectedElemets, group]);

    const handleActivate = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (editingGroup || editing) return;

        let target: unknown = e.target;
        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (target.getAttribute('data-type') === 'action') return;
            target = target.parentElement;
        }

        if (selected) manager.unselectElement(group);
        else manager.selectOneElement(group);
    }, [group, manager, selected, editing, editingGroup]);

    const group_hidden = useMemo(() => Boolean(segmentsOfGroup.length) && !segmentsOfGroup.some(segment => segment.state === SpriteState.REGULAR), [segmentsOfGroup]);

    const handleCheckbox = useCallback(() => {
        if (matchDefinition) return;
        if (editingGroup || editing || matchDefinition) return;
        if (selected) manager.unselectElement(group);
        else manager.selectElement(group);
    }, [selected, manager, group, editing, editingGroup, matchDefinition]);

    const onMouseEnter = useCallback((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {
        if (matchDefinition && (group.description || group.attachments.length) && (!manager.matchDefinition || !manager.matchDefinition.full)) manager.matchDefinition = { element: group, full: false, x: e.clientX, y: e.clientY };
        else manager.hoveredElements = [group];
    }, [group, manager, matchDefinition]);

    const onMouseLeave = useCallback(() => {
        if (manager.matchDefinition && !manager.matchDefinition.full && manager.matchDefinition.element === group) manager.matchDefinition = null;
        if (manager.hoveredElements[0] === group) manager.hoveredElements = [];
    }, [group, manager]);

    const onBlur = useCallback(() => {
        if (prevDescription === description) return;
        else manager.saveSketch();
    }, [manager, prevDescription, description]);

    const inputFocus = useCallback(() => {
        const input = document.getElementById(`group_${group.id}_input`);
        if (!input) throw new Error('Input not exist.');
        input.focus();
    }, [group]);

    const [segmentsHeight, openSegmentsHeight, closeSegmentsHeight] = useElementHeight(segmentsElement);
    const [attachmentsHeignt, openAttachmentsHeignt, closeAttachmentsHeignt] = useElementHeight(attachmentsElement);

    const openSegments = useCallback(() => {
        if (!segmentsOfGroup.length) return;
        if (attachmentsIsOpened) setAttachmentsIsOpened(prev => {
            if (prev) closeAttachmentsHeignt();
            else openAttachmentsHeignt();
            return !prev;
        });

        setSegmentsIsOpened(prev => {
            if (prev) closeSegmentsHeight();
            else openSegmentsHeight();
            return !prev;
        });
    }, [attachmentsIsOpened, segmentsOfGroup, openSegmentsHeight, closeSegmentsHeight, openAttachmentsHeignt, closeAttachmentsHeignt]);

    const openAttachments = useCallback(() => {
        if (segmentsIsOpened) setSegmentsIsOpened(prev => {
            if (prev) closeSegmentsHeight();
            else openSegmentsHeight();
            return !prev;
        });

        setAttachmentsIsOpened(prev => {
            if (prev) closeAttachmentsHeignt();
            else openAttachmentsHeignt();
            return !prev;
        });
    }, [segmentsIsOpened, openAttachmentsHeignt, closeAttachmentsHeignt, openSegmentsHeight, closeSegmentsHeight]);

    const openAttachmentsWindow = useCallback(() => {
        if (editingGroup) return;
        setNotification(Notifications.MORE_ATTACHMENTS_INFO);
        setElementForAttach(group);
    }, [editingGroup, group]);

    const editGroup = useCallback(() => manager.editingGroup = group, [manager, group]);

    return (
        <Draggable draggableId={group.id} index={index}>
            {(providedGroup, snapshotGroup) => (
                <div {...providedGroup.draggableProps} ref={providedGroup.innerRef}>
                    <div
                        id={'element_' + group.id}
                        className={classNames({ group: true, 'group_is-being-dragged': snapshotGroup.isDragging, group_active: selected, group_hidden, 'group_border-bottom': true })}
                        onClick={e => matchDefinition ? matchDefinition(e, group.id) : handleActivate(e)}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onDoubleClick={editGroup}
                    >
                        <div className="group__cell1">
                            <div {...providedGroup.dragHandleProps} className="group__drag-handler-container" tabIndex={-1}>
                                <DragHandleSVG />
                            </div>
                            {
                                selected
                                    ?
                                    <CheckboxCheckedSVG className="pointer" onClick={handleCheckbox} data-type="action" />
                                    :
                                    <CheckboxSVG className="pointer" onClick={handleCheckbox} data-type="action" />
                            }
                        </div>
                        <div className="group__cell2">
                            <div className={classNames({ 'group__open-segments': true, 'group__open-segments_disabled': !segmentsOfGroup.length })} onClick={openSegments} data-type="action">
                                <GroupSVG />
                                <TriangleSVG className={classNames({ 'group__open-button': true, 'group__open-button_opened': segmentsIsOpened })} />
                            </div>
                        </div>
                        <RenderingCell group={group} />
                        <div className="group__cell3" onClick={inputFocus} data-type="action">
                            <TextareaAutosize
                                maxRows={4}
                                id={`group_${group.id}_input`}
                                className="group__description"
                                placeholder="Type definition for the group of similar objects."
                                value={description}
                                onChange={e => group.description = e.currentTarget.value}
                                onFocus={() => setPrevDescription(description)}
                                onBlur={onBlur}
                                disabled={Boolean(editingGroup)}
                            />
                        </div>
                        <div className="group__cell4">
                            {
                                Boolean(attachments.length)
                                &&
                                <div className="group__open-attachments" onClick={openAttachments} data-type="action">
                                    <span>{attachments.length}</span>
                                    <TriangleSVG className={classNames({ 'group__cell4-triangle': true, 'group__cell4-triangle_opened': attachmentsIsOpened })} />
                                </div>
                            }
                        </div>
                        <div className="group__cell5">
                            <div className={classNames({ 'group__add-attach-icon-container': true, 'group__add-attach-icon-container_disabled': editingGroup })} onClick={openAttachmentsWindow} data-type="action">
                                <AddAttachSVG />
                            </div>
                        </div>
                        <div className="group__cell6">
                            <div className={classNames({ 'group__ungroup-icon-container': true, 'group__ungroup-icon-container_disabled': inpainting })} onClick={() => manager.ungroup([group])} data-type="action">
                                <UngroupSVG />
                            </div>
                        </div>
                        {
                            (editingGroup || inpainting)
                            &&
                            <div className="group__read-only-container">
                                <div className="group__read-only-background">
                                    <div className="group__read-only">
                                        <ReadOnlySVG />
                                        <span>Read only</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="segments-of-group" ref={segmentsElement} style={{ height: segmentsHeight }}>
                        {segmentsOfGroup.map((segment, index) => <GroupSegmentElement matchDefinition={matchDefinition} group={group} segment={segment} key={segment.id} index={index} />)}
                    </div>
                    <div className="group__attachments" ref={attachmentsElement} style={{ height: attachmentsHeignt }}>
                        {attachments.map(attachment => <AttachmentComponent element={group} attachment={attachment} key={attachment.id} />)}
                    </div>
                </div>
            )}
        </Draggable>
    );
}

type SegmentElementProps = {
    segment: Segment;
    group: GroupClass;
    dragHandle?: any;
    isDragging?: boolean;
    index: number;
    matchDefinition: undefined | ((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, id?: string) => void);
};

function GroupSegmentElement({ segment, group, dragHandle = {}, isDragging = false, index, matchDefinition }: SegmentElementProps) {
    const manager = useManager('self');
    const selectedElemets = useManager('selectedElements');
    const hoveredElements = useManager('hoveredElements');
    const editingGroup = useManager('editingGroup');
    const editing = useEditor('editing');
    const description = useSegment(segment, 'description');
    const attachments = useSegment(segment, 'attachments');
    const [attachmentsIsOpened, setAttachmentsIsOpened] = useState(false);
    const [opened, setOpened] = useState(false);
    const attachmentsElement = useRef<HTMLDivElement | null>(null);

    const selected = useMemo(() => selectedElemets.includes(segment), [selectedElemets, segment]);

    const handleSelect = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (editingGroup || editing) return;
        let target: unknown = e.target;

        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (target.getAttribute('data-type') === 'action') return;
            target = target.parentElement;
        }

        manager.selectOneElement(segment);
    }, [segment, manager, editing, editingGroup]);

    const handleCheckbox = useCallback(() => {
        if (matchDefinition) return;
        if (editingGroup || editing || matchDefinition) return;
        if (selected) manager.unselectElement(segment);
        else manager.selectElement(segment);
    }, [selected, manager, segment, editing, editingGroup, matchDefinition]);

    const onMouseEnter = useCallback((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {
        if (matchDefinition && (segment.description || segment.attachments.length) && (!manager.matchDefinition || !manager.matchDefinition.full)) manager.matchDefinition = { element: segment, full: false, x: e.clientX, y: e.clientY };
        else manager.hoveredElements = [segment];
    }, [segment, manager, matchDefinition]);

    const onMouseLeave = useCallback(() => {
        if (manager.matchDefinition && !manager.matchDefinition.full && manager.matchDefinition.element === segment) manager.matchDefinition = null;
        if (manager.hoveredElements[0] === segment) manager.hoveredElements = [];
    }, [segment, manager]);

    const warning = useMemo(() => editingGroup === group && (Boolean(segment.description) || Boolean(segment.attachments.length)), [segment, editingGroup, group]);

    const [attachmentsHeignt, openAttachmentsHeignt, closeAttachmentsHeignt] = useElementHeight(attachmentsElement);

    const openAttachments = useCallback(() => {
        setAttachmentsIsOpened(prev => {
            if (prev) closeAttachmentsHeignt();
            else openAttachmentsHeignt();
            return !prev;
        });
    }, [openAttachmentsHeignt, closeAttachmentsHeignt]);

    return (
        <div
            onMouseLeave={() => setOpened(false)}
            className={classNames({
                'group-segment__container': true,
                'group-segment__container_active': selected,
                'group-segment__container_dragging': isDragging,
                'group-segment__container_hover': hoveredElements[0] === segment,
                'group-segment__container_opened': opened
            })}
        >
            <div className="group-segment" onClick={e => matchDefinition ? matchDefinition(e, segment.id) : handleSelect(e)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div className="group-segment__cell1">
                    <div {...dragHandle} className="group-segment__drag-handler-container" tabIndex={-1}>
                        <DragHandleSVG />
                    </div>
                    {
                        selected
                            ?
                            <CheckboxCheckedSVG className="pointer" onClick={handleCheckbox} data-type="action" />
                            :
                            <CheckboxSVG className="pointer" onClick={handleCheckbox} data-type="action" />
                    }
                </div>
                <div className="group-segment__cell2">
                    {warning && <div
                        className="group-segment__warning-icon"
                        id={'definitions_warning' + segment.id}
                        onMouseEnter={() => setHint({ id: 'definitions_warning', index: segment.id })}
                    />}
                </div>
                <div className="group-segment__cell3">
                    <div
                        id={`color_circle_${index}`}
                        onMouseEnter={() => setHint({ id: 'color_circle_', index: index.toLocaleString() })}
                        className={classNames({ 'group-segment__color': true, 'group-segment__color_active': selected })}
                        style={{ backgroundColor: `rgb(${segment.color[0]}, ${segment.color[1]}, ${segment.color[2]})` }}
                        onClick={() => manager.changeToRandomColor(segment)}
                        data-type="action"
                    />
                    <div>{segment.name.toString().padStart(3, '0')}</div>
                </div>
                <div className="group-segment__cell4 group-segment__background-hover">
                    <TextareaAutosize
                        maxRows={4}
                        disabled
                        value={description}
                        className="group-segment__description"
                        placeholder="Type and/or attach to define"
                        data-type="action"
                    />
                </div>
                <div className="group-segment__cell5 group-segment__background-hover">
                    {
                        Boolean(attachments.length)
                        &&
                        <>
                            <span>{attachments.length}</span>
                            <TriangleSVG
                                className={classNames({ 'group-segment__cell5-triangle': true, 'group-segment__cell5-triangle_opened': attachmentsIsOpened })}
                                onClick={openAttachments}
                                data-type="action"
                            />
                        </>
                    }
                </div>
                <div className="group-segment__cell6 group-segment__background-hover" />
                <div className="group-segment__guard-container" onClick={() => setOpened(true)}>
                    <div className="group-segment__guard">
                        <div className="group-segment__guard-child">
                            <DisabledSVG />
                            <span>Disabled</span>
                        </div>
                        <div className="group-segment__guard-child">
                            <ReadOnlySVG />
                            <span>Read only</span>
                        </div>
                    </div>
                </div>
                <div className="group-segment__last-cell group-segment__background-hover" />
            </div>
            <div className="group-segment__attachments" ref={attachmentsElement} style={{ height: attachmentsHeignt }}>
                {attachments.map(attachment => <GroupSegmentAttachmentComponent element={segment} attachment={attachment} key={attachment.id} />)}
            </div>
        </div>
    );
}

function RenderingCell({ group }: { group: GroupClass }) {
    const manager = useManager('self');
    const inpainting = useManager('inpainting');
    const inpaintingQueue = useManager('inpaintingQueue');
    const description = useGroup(group, 'description');
    const attachments = useGroup(group, 'attachments');

    const disabled = useMemo(() => !(description || attachments.length), [description, attachments]);

    const icon = useMemo(() => {
        if (!inpainting) return <RenderSVG className={classNames({ 'segment__render-icon': true, disabled })} onClick={() => manager.addToInpaintingQueue(group)} />;
        if (inpainting.element === group) return <RenderingSVG className="segment__render-icon segment__rendering-icon" onClick={() => manager.cancelInpainting(group)} />
        if (inpaintingQueue.includes(group)) return <WaitingRenderSVG className="segment__render-icon segment__waiting-icon" onClick={() => manager.cancelInpainting(group)} />;
        else return <RenderSVG className="segment__render-icon" onClick={() => manager.addToInpaintingQueue(group)} />;
    }, [inpainting, group, manager, inpaintingQueue, disabled]);

    return (
        <div className="segment__rendering-cell">
            {icon}
        </div>
    );
}
