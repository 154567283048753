import { useState } from 'react';
import classNames from 'classnames';
import { setElementForAttach } from 'entities/library';
import Upload from './Upload';
import Button from 'Components/Button';
import { Group, Segment } from 'entities/sketch/Manager';
import Products from './Products';
import { useHotKey } from 'hooks';
import { setNotification } from 'entities/notification';
import './Attachments.scss';


export enum Tab {
    UPLOAD = 'UPLOAD',
    PRODUCTS = 'PRODUCTS',
}

type AttachmentsProps = {
    element: Segment | Group | null;
};

export default function Attachments({ element }: AttachmentsProps) {
    const [tab, setTab] = useState(Tab.UPLOAD);

    useHotKey('Escape', () => { setNotification(null); setElementForAttach(null) });
    useHotKey('Enter', () => { setNotification(null); document.getElementById('apply-attachments')?.click() });

    return (
        <div className={`attachments__container ${element ? 'open' : ' '}`}>
            {element &&
                <div className="attachments">
                    <div className="attachments_header">
                        <div className="attachments_header-segment_info">
                            <div className="attachments_header-icon" />
                            <div className="header-segment_info-color">
                                <p>Add attachments</p>
                                <div className="info-color">
                                    {'color' in element && <div className="segment__color" style={{ backgroundColor: `rgb(${element.color[0]}, ${element.color[1]}, ${element.color[2]})` }} />}
                                </div>
                            </div>

                        </div>
                        <div
                            className="attachments_header-close"
                            onClick={() => {
                                setElementForAttach(null);
                                setNotification(null)
                                setTab(Tab.UPLOAD)
                            }}
                        >
                            <p>close</p>
                            <div className="attachments__close-button" />
                        </div>
                    </div>
                    <div className="attachments__menu">
                        <div className={classNames({ 'attachments__menu-button': true, 'attachments__menu-button_active': tab === Tab.UPLOAD, is_beta: true })} onClick={() => setTab(Tab.UPLOAD)}>UPLOAD
                            <div className="menu-button__beta-marker">beta</div>
                        </div>
                        <div className={classNames({ 'attachments__menu-button': true, 'attachments__menu-button_active': tab === Tab.PRODUCTS })} onClick={() => setTab(Tab.PRODUCTS)}>PRODUCTS</div>
                    </div>
                    {tab === Tab.UPLOAD && <Upload element={element} setTab={setTab} />}
                    {tab === Tab.PRODUCTS && <Products element={element} setTab={setTab} />}
                    {tab !== Tab.PRODUCTS &&
                        <div className="attachments__apply_footer">
                            <Button color="dark" size="medium" className="attachments__apply-button" id="apply-attachments">ADD</Button>
                        </div>
                    }
                </div>}
        </div>
    );
}
